import React, { useState } from "react";
import { AlertCircle, Check, Mail, Lock, Eye, EyeOff } from "lucide-react";
import { useAuth } from "./AuthContext"; // Uistite sa, že cesta k súboru je správna

const LoginSignup = () => {
  const { signUp, signIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const { error } = isSignUp
        ? await signUp({ email, password })
        : await signIn({ email, password });

      if (error) throw error;

      setSuccess(
        isSignUp
          ? "Sign up successful! Please check your email for verification."
          : "Login successful!"
      );
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-deep-blue to-lavender p-4">
      <div className="w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden">
        <div className="p-8">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-deep-blue">
            {isSignUp ? "Create Account" : "Welcome Back"}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-warm-gray mb-1"
              >
                Email
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-light-gray rounded-md focus:outline-none focus:ring-2 focus:ring-mint-green focus:border-mint-green sm:text-sm"
                  placeholder="you@example.com"
                  required
                  autoComplete="email" // Pridajte tento riadok
                />
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-warm-gray mb-1"
              >
                Password
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full pl-10 pr-10 py-2 border border-light-gray rounded-md focus:outline-none focus:ring-2 focus:ring-mint-green focus:border-mint-green sm:text-sm"
                  placeholder="••••••••"
                  required
                  autoComplete={isSignUp ? "new-password" : "current-password"} // Pridajte tento riadok
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  >
                    {showPassword ? (
                      <EyeOff className="h-5 w-5" />
                    ) : (
                      <Eye className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mint-green"
            >
              {isSignUp ? "Sign Up" : "Log In"}
            </button>
          </form>
          <div className="mt-6 text-center">
            <button
              onClick={() => setIsSignUp(!isSignUp)}
              className="text-deep-blue hover:text-lavender transition duration-300 ease-in-out text-sm"
            >
              {isSignUp
                ? "Already have an account? Log In"
                : "Don't have an account? Sign Up"}
            </button>
          </div>
        </div>
        {error && (
          <div className="p-4 bg-red-100 border-t border-red-400 text-red-700 text-sm">
            <div className="flex items-center">
              <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
              <p>{error}</p>
            </div>
          </div>
        )}
        {success && (
          <div className="p-4 bg-green-100 border-t border-green-400 text-green-700 text-sm">
            <div className="flex items-center">
              <Check className="w-5 h-5 mr-2 flex-shrink-0" />
              <p>{success}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginSignup;
