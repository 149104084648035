import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Cloud, Waves, Flame, Volume2, VolumeX } from "lucide-react";
import { Slider } from "./ui/slider";
import { Button } from "./ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

const BackgroundNoiseSelector = ({
  backgroundNoises,
  onNoiseToggle,
  onVolumeChange,
}) => {
  const [openPopover, setOpenPopover] = useState(null);

  const getIcon = (noiseName) => {
    switch (noiseName.toLowerCase()) {
      case "rain":
        return <Cloud className="w-5 h-5" />;
      case "ocean":
        return <Waves className="w-5 h-5" />;
      case "fireplace":
        return <Flame className="w-5 h-5" />;
      default:
        return null;
    }
  };

  const getAnimation = (noiseName, isActive) => {
    if (!isActive) return {};
    switch (noiseName.toLowerCase()) {
      case "rain":
        return {
          backgroundImage:
            "radial-gradient(circle, #3490dc 1px, transparent 1px)",
          backgroundSize: "10px 10px",
          animation: "rain 3s linear infinite",
        };
      case "ocean":
        return {
          backgroundImage: "linear-gradient(to right, #4299e1, #3182ce)",
          backgroundSize: "200% 100%",
          animation: "waves 5s ease infinite",
        };
      case "fireplace":
        return {
          backgroundImage:
            "radial-gradient(ellipse at bottom, #f6ad55, #ed8936, #9c4221)",
          backgroundSize: "200% 200%",
          animation: "fire 3s ease infinite",
        };
      default:
        return {};
    }
  };

  const handleVolumeChange = (noiseName, value) => {
    onVolumeChange(noiseName, value / 100);
  };

  const toggleMute = (noise) => {
    onVolumeChange(noise.name, noise.volume === 0 ? 0.5 : 0);
  };

  return (
    <div className="flex flex-wrap justify-center gap-3">
      {backgroundNoises.map((noise) => (
        <Popover
          key={noise.name}
          open={openPopover === noise.name}
          onOpenChange={(isOpen) => setOpenPopover(isOpen ? noise.name : null)}
        >
          <PopoverTrigger asChild>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button
                variant="ghost"
                size="sm"
                className={`w-24 h-24 rounded-xl flex flex-col items-center justify-center space-y-2 border ${
                  noise.isActive
                    ? "border-amber-500 bg-gray-800/80"
                    : "border-gray-700 bg-gray-900/50"
                } overflow-hidden hover:bg-gray-700/50 transition-colors`}
                onClick={() => onNoiseToggle(noise.name)}
                aria-label={`Toggle ${noise.name} background noise`}
                style={getAnimation(noise.name, noise.isActive)}
              >
                <div
                  className={`p-2 rounded-full z-10 ${
                    noise.isActive ? "bg-amber-500" : "bg-gray-700"
                  }`}
                >
                  {getIcon(noise.name)}
                </div>
                <span className="text-sm font-medium truncate text-white z-10">
                  {noise.name}
                </span>
                {noise.isActive && (
                  <motion.div
                    animate={{ scale: [1, 1.2, 1] }}
                    transition={{ repeat: Infinity, duration: 2 }}
                    className="z-10"
                  >
                    {noise.volume > 0 ? (
                      <Volume2 className="w-4 h-4 text-amber-400" />
                    ) : (
                      <VolumeX className="w-4 h-4 text-gray-400" />
                    )}
                  </motion.div>
                )}
              </Button>
            </motion.div>
          </PopoverTrigger>
          <AnimatePresence>
            {noise.isActive && (
              <PopoverContent
                className="w-56 p-3 border border-gray-700  bg-black/50 backdrop-blur-sm rounded-xl shadow-lg"
                sideOffset={5}
              >
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium text-gray-300">
                      Volume
                    </span>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="p-1"
                      onClick={() => toggleMute(noise)}
                    >
                      {noise.volume > 0 ? (
                        <Volume2 className="w-4 h-4 text-amber-400" />
                      ) : (
                        <VolumeX className="w-4 h-4 text-gray-400" />
                      )}
                    </Button>
                  </div>
                  <Slider
                    value={[noise.volume * 100]}
                    onValueChange={(value) =>
                      handleVolumeChange(noise.name, value[0])
                    }
                    max={100}
                    step={1}
                    className="w-full"
                    aria-label={`Adjust volume for ${noise.name}`}
                  />
                  <div className="mt-2 text-right">
                    <span className="text-xs text-amber-400">
                      {Math.round(noise.volume * 100)}%
                    </span>
                  </div>
                </motion.div>
              </PopoverContent>
            )}
          </AnimatePresence>
        </Popover>
      ))}
      <style jsx global>{`
        @keyframes rain {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: 0 20px;
          }
        }
        @keyframes waves {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        @keyframes fire {
          0% {
            background-position: 0% 100%;
          }
          50% {
            background-position: 100% 0%;
          }
          100% {
            background-position: 0% 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default BackgroundNoiseSelector;
