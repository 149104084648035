import { useState, useEffect, useRef, useCallback } from "react";

export const useAudio = (tracks, initialVolume = 1, backgroundNoises = []) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [volume, setVolume] = useState(initialVolume);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [onPlayStartCallback, setOnPlayStartCallback] = useState(null);
  const audioRef = useRef(null);
  const backgroundAudioRefs = useRef(backgroundNoises.map(() => new Audio()));
  const [previousState, setPreviousState] = useState(null);

  const togglePlayback = useCallback(() => {
    setIsPlaying((prev) => {
      if (!prev) {
        // Music is about to start playing
        onPlayStartCallback && onPlayStartCallback();
      }
      return !prev;
    });
  }, [onPlayStartCallback]);

  const skipToNext = useCallback(() => {
    setTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
    setIsPlaying(true);
    onPlayStartCallback && onPlayStartCallback();
  }, [tracks.length, onPlayStartCallback]);

  const skipToPrevious = useCallback(() => {
    setTrackIndex(
      (prevIndex) => (prevIndex - 1 + tracks.length) % tracks.length
    );
    setIsPlaying(true);
    onPlayStartCallback && onPlayStartCallback();
  }, [tracks.length, onPlayStartCallback]);

  const stopAllAudio = useCallback(() => {
    const currentState = {
      isPlaying,
      trackIndex,
      currentTime: audioRef.current.currentTime,
      backgroundNoises: backgroundNoises.map((noise, index) => ({
        ...noise,
        currentTime: backgroundAudioRefs.current[index].currentTime,
      })),
    };
    setPreviousState(currentState);

    setIsPlaying(false);
    audioRef.current.pause();
    backgroundAudioRefs.current.forEach((audio) => audio.pause());
  }, [isPlaying, trackIndex, backgroundNoises]);

  const resumeAllAudio = useCallback(() => {
    if (previousState) {
      setIsPlaying(previousState.isPlaying);
      setTrackIndex(previousState.trackIndex);
      audioRef.current.currentTime = previousState.currentTime;

      previousState.backgroundNoises.forEach((noise, index) => {
        const audio = backgroundAudioRefs.current[index];
        audio.currentTime = noise.currentTime;
        if (noise.isActive) {
          audio.play().catch(console.error);
        }
      });

      if (previousState.isPlaying) {
        audioRef.current.play().catch(console.error);
        onPlayStartCallback && onPlayStartCallback();
      }

      setPreviousState(null);
    }
  }, [previousState, onPlayStartCallback]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.volume = volume;
    if (isPlaying) {
      audio.play().catch(console.error);
    } else {
      audio.pause();
    }
  }, [isPlaying, trackIndex, volume]);

  useEffect(() => {
    const audio = audioRef.current;
    const updateTime = () => setCurrentTime(audio.currentTime);
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    };
    const handleEnded = () => {
      skipToNext();
    };

    audio.addEventListener("loadedmetadata", setAudioData);
    audio.addEventListener("timeupdate", updateTime);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("timeupdate", updateTime);
      audio.removeEventListener("ended", handleEnded);
    };
  }, [skipToNext]);

  useEffect(() => {
    backgroundNoises.forEach((noise, index) => {
      const audio = backgroundAudioRefs.current[index];
      audio.src = noise.audioSrc;
      audio.loop = true;
      audio.volume = noise.volume;

      if (noise.isActive) {
        audio
          .play()
          .catch((error) =>
            console.error("Error playing background noise:", error)
          );
      } else {
        audio.pause();
      }
    });

    return () => {
      backgroundAudioRefs.current.forEach((audio) => audio.pause());
    };
  }, [backgroundNoises]);

  const setBackgroundNoiseVolume = useCallback((index, newVolume) => {
    backgroundAudioRefs.current[index].volume = newVolume;
  }, []);

  const playTrack = useCallback(
    (index) => {
      setTrackIndex(index);
      setIsPlaying(true);
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.play().catch(console.error);
      }
      onPlayStartCallback && onPlayStartCallback();
    },
    [onPlayStartCallback]
  );

  return {
    isPlaying,
    trackIndex,
    volume,
    currentTime,
    duration,
    audioRef,
    togglePlayback,
    skipToNext,
    skipToPrevious,
    setVolume,
    setCurrentTime,
    setBackgroundNoiseVolume,
    stopAllAudio,
    resumeAllAudio,
    setOnPlayStartCallback,
    playTrack,
  };
};

export default useAudio;
