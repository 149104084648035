import React from "react";
import { User, LogOut, List } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const HeaderSection = ({
  genre,
  onGenreChange,
  showSidebar,
  setShowSidebar,
  user,
  showProfileMenu,
  setShowProfileMenu,
  handleLogout,
}) => {
  return (
    <div className="flex items-center justify-between p-6 bg-transparent">
      <h1 className="text-2xl font-bold text-white">Productive.fm</h1>
      <div className="flex items-center space-x-4">
        <Select value={genre} onValueChange={onGenreChange}>
          <SelectTrigger className="w-[120px] bg-white/10 text-white border-none">
            <SelectValue placeholder="Select genre" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="lofi">Lofi</SelectItem>
            <SelectItem value="lofi hawaii">Hawaiian Lofi</SelectItem>

            <SelectItem value="classical">Classical</SelectItem>
            {/* <SelectItem value="jazz">Jazz</SelectItem> */}
            {/* <SelectItem value="ambient">Ambient</SelectItem> */}
          </SelectContent>
        </Select>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setShowSidebar(!showSidebar)}
          className="text-white hover:bg-white/20"
        >
          <List className="w-5 h-5" />
        </Button>
        <div className="relative">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setShowProfileMenu(!showProfileMenu)}
            className="text-white hover:bg-white/20"
          >
            <User className="w-5 h-5" />
          </Button>
          <AnimatePresence>
            {showProfileMenu && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 z-10"
              >
                <div className="py-1" role="menu" aria-orientation="vertical">
                  <div className="px-4 py-2 text-sm text-gray-300">
                    {user?.email}
                  </div>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                  >
                    <LogOut className="w-4 h-4 inline-block mr-2" />
                    Logout
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
