import { useState, useEffect, useRef, useCallback } from "react";

export const useTimer = (initialMinutes = 25) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const timerRef = useRef(null);

  const startTimer = useCallback(
    (callback) => {
      if (minutes > 0 || seconds > 0) {
        setIsActive(true);
        callback && callback();
      }
    },
    [minutes, seconds]
  );

  const stopTimer = useCallback(() => setIsActive(false), []);

  const resetTimer = useCallback(() => {
    setIsActive(false);
    setMinutes(initialMinutes);
    setSeconds(0);
  }, [initialMinutes]);

  useEffect(() => {
    if (isActive) {
      timerRef.current = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            if (minutes === 0) {
              clearInterval(timerRef.current);
              setIsActive(false);
              return 0;
            }
            setMinutes((prevMinutes) => prevMinutes - 1);
            return 59;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isActive, minutes]);

  return {
    minutes,
    seconds,
    isActive,
    setMinutes,
    setSeconds,
    startTimer,
    stopTimer,
    resetTimer,
  };
};
