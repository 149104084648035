import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import TrackInfo from "./components/TrackInfo";

import HeaderSection from "./components/HeaderSection";
import { Input } from "./components/ui/input";

import {
  X,
  LogOut,
  Pause,
  Play,
  ThumbsUp,
  Share2,
  SkipForward,
  SkipBack,
  Volume2,
  Square,
  VolumeX,
  List,
} from "lucide-react";
import { useAuth } from "./AuthContext";
import { useAudio } from "./hooks/useAudio";
import { useTimer } from "./hooks/useTimer";
import { Button } from "./components/ui/button";
import { TodoInput, TodoList } from "./components/TodoInput";
import SpeedAndGenreSelector from "./components/SpeedModeSelector";
import BackgroundNoiseSelector from "./components/BackgroundNoiseSelector";
import BreathingAnimation from "./components/BreathingAnimation";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Card, CardContent } from "./components/ui/card";

import { Slider } from "./components/ui/slider";

const MusicPlayer = () => {
  const { user, signOut } = useAuth();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [speedMode, setSpeedMode] = useState("normal");
  const [showBreathingAnimation, setShowBreathingAnimation] = useState(false);
  const [todos, setTodos] = useState([]);
  const [genre, setGenre] = useState("lofi");
  const profileMenuRef = useRef(null);
  const [isEditingTimer, setIsEditingTimer] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const todoInputRef = useRef(null);

  const [backgroundNoises, setBackgroundNoises] = useState([
    { name: "Rain", audioSrc: "/music/rain.mp3", isActive: false, volume: 0.5 },
    {
      name: "Ocean",
      audioSrc: "/music/ocean.mp3",
      isActive: false,
      volume: 0.5,
    },
    {
      name: "Fireplace",
      audioSrc: "/music/fireplace.mp3",
      isActive: false,
      volume: 0.5,
    },
  ]);

  const tracks = [
    {
      name: "Hawaii Vibes",
      genre: "lofi hawaii",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1542332213-9b5a5a3fad35?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Hawaii1.mp3",
      details: "...",
    },
    {
      name: "Hawaii Vibes2",
      genre: "lofi hawaii",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1468581264429-2548ef9eb732?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Hawaii2.mp3",
      details: "...",
    },
    {
      name: "Hawaii Vibes3",
      genre: "lofi hawaii",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1465634836201-1d5651b9b6d6?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Hawaii3.mp3",
      details: "...",
    },
    {
      name: "Sunset Vibes",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Sunset Vibes.mp3",
      details: "Relaxing tunes perfect for watching the sunset.",
    },
    {
      name: "Night Stroll",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Night Stroll.mp3",
      details: "Smooth beats for a peaceful night walk.",
    },
    {
      name: "Lazy Afternoon",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Lazy Afternoon.mp3",
      details: "Perfect for unwinding on a lazy afternoon.",
    },
    {
      name: "City Lights",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/City Lights.mp3",
      details: "Chill beats inspired by the city's night lights.",
    },
    {
      name: "Morning Coffee",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Morning Coffee.mp3",
      details: "Perfect tunes to enjoy with your morning coffee.",
    },
    {
      name: "Rainy Days",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Rainy Days.mp3",
      details: "Soothing music for a rainy day.",
    },
    {
      name: "Chill Breeze",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Chill Breeze.mp3",
      details: "Relaxing tunes carried by a gentle breeze.",
    },
    {
      name: "Starlit Night",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Starlit Night.mp3",
      details: "Perfect for stargazing on a clear night.",
    },
    {
      name: "Summer Chill",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Summer Chill.mp3",
      details: "Just chill nothing more",
    },
    {
      name: "Wawes",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Wawes.mp3",
      details: "Just chill nothing more",
    },
    {
      name: "Sunshine Wawes",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Sunshine Waves.mp3",
      details: "Just chill nothing more",
    },
    {
      name: "Forest Walk",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Forest Walk.mp3",
      details: "Calming beats for a peaceful forest walk.",
    },
    {
      name: "Daydream",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Daydream.mp3",
      details: "Music to accompany your daydreams.",
    },
    {
      name: "Mellow Mood",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Mellow Mood.mp3",
      details: "Relaxing tunes for a mellow mood.",
    },
    {
      name: "Dreamy Nights",
      genre: "lofi chill",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
      audioSrc: "/music/Dreamy Nights.mp3",
      details: "Perfect for drifting off to dreamland.",
    },

    {
      name: "Bursting Through the Night",
      genre: "classical",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Bursting Through the Night.mp3",
      details: "Konchivawa",
    },
    {
      name: "Rhytm Class",
      genre: "classical",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Rhytm Class.mp3",
      details: "Konchivawa",
    },
    {
      name: "Into the Storm",
      genre: "classical",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Into the Storm.mp3",
      details: "Konchivawa",
    },
    {
      name: "Whispers of Sun",
      genre: "classical",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Whispers of Sun.mp3",
      details: "Konchivawa",
    },
    {
      name: "Whispers of the Heart",
      genre: "classical",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Whispers of the Heart.mp3",
      details: "Konchivawa",
    },
    {
      name: "Echoes in the past",
      genre: "classical",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Echoes in the past.mp3",
      details: "Konchivawa",
    },
    {
      name: "Echoes in the Rain",
      genre: "classical",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/Echoes in the Rain.mp3",
      details: "Konchivawa",
    },
    {
      name: "Sakura Dreams",
      genre: "lofi",
      artist: "AI",
      albumArt:
        "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/JapanLofi1.mp3",
      details: "Konchivawa",
    },
    {
      name: "Night Stroll in Shibuya",
      artist: "AI",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1702118177614-c4867d0cf7cd?q=80&w=2617&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/JapanLofi2.mp3",
      details: "Konchivawa",
    },
    {
      name: "Whispers of Kyoto",
      artist: "Japanese Lofi",
      genre: "lofi",

      albumArt:
        "https://plus.unsplash.com/premium_photo-1669876271015-55e215f60bc4?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/JapanLofi3.mp3",
      details: "Konchivawa",
    },
    {
      name: "Eternal Sunset",
      artist: "Japanese Lofi",
      genre: "lofi",

      albumArt:
        "https://images.unsplash.com/photo-1524721696987-b9527df9e512?q=80&w=2533&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/JapanLofi4.mp3",
      details: "Konchivawa",
    },
    {
      name: "Rainy Day Reflections",
      artist: "Japanese Lofi",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1481966115753-963394378f23?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/JapanLofi5.mp3",
      details: "Konchivawa",
    },
    {
      name: "Tokyo Twilight",
      artist: "Japanese Lofi",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/flagged/photo-1567400358593-9e6382752ea2?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/JapanLofi6.mp3",
      details: "Konchivawa",
    },
    {
      name: "J l",
      artist: "Lofi Beats",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/song1.mp3",
      details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      name: "Mo nigh",
      artist: "Lofi Beats",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1717501218221-1da14c0f365e?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/song2.mp3",
      details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      name: "Just Peaches",
      artist: "Lofi Beats",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1717501218221-1da14c0f365e?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/song3.mp3",
      details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      name: "Just 4",
      artist: "Lofi Beats",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1717501218221-1da14c0f365e?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/song4.mp3",
      details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      name: "5 nos",
      artist: "Lofi Beats",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/song5.mp3",
      details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      name: "6 and beyond",
      artist: "Lofi Beats",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/song6.mp3",
      details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      name: "Lucky 7",
      artist: "Lofi Beats",
      genre: "lofi",
      albumArt:
        "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      audioSrc: "/music/song7.mp3",
      details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    // ... other tracks
  ];

  const filteredTracks = useMemo(() => {
    return tracks.filter((track) => track.genre === genre);
  }, [tracks, genre]);

  const {
    isPlaying,
    trackIndex,
    volume,
    currentTime,
    duration,
    audioRef,
    togglePlayback,
    skipToNext,
    skipToPrevious,
    setVolume,
    setCurrentTime,
    setBackgroundNoiseVolume,
    stopAllAudio,
    resumeAllAudio,
    playTrack,
  } = useAudio(filteredTracks, 1, backgroundNoises);

  const {
    minutes: timerMinutes,
    seconds: timerSeconds,
    isActive: timerActive,
    setMinutes: setTimerMinutes,
    setSeconds: setTimerSeconds,
    startTimer,
    stopTimer,
    resetTimer,
  } = useTimer();

  const handlePlayPauseStop = useCallback(() => {
    if (isPlaying) {
      if (isStopped) {
        togglePlayback();
        startTimer(() => {
          setShowBreathingAnimation(true);
          // Hide the BreathingAnimation after 2 seconds
          setTimeout(() => {
            setShowBreathingAnimation(false);
          }, 700);
        });
      } else {
        stopAllAudio();
        stopTimer();
        setIsStopped(true);
      }
    } else {
      if (isStopped) {
        resumeAllAudio();
        startTimer(() => {
          setShowBreathingAnimation(true);
          // Hide the BreathingAnimation after 2 seconds
          setTimeout(() => {
            setShowBreathingAnimation(false);
          }, 7000);
        });
        setIsStopped(false);
      } else {
        togglePlayback();
        startTimer(() => {
          setShowBreathingAnimation(true);
          // Hide the BreathingAnimation after 2 seconds
          setTimeout(() => {
            setShowBreathingAnimation(false);
          }, 7000);
        });
      }
    }
  }, [
    isPlaying,
    isStopped,
    togglePlayback,
    stopAllAudio,
    resumeAllAudio,
    startTimer,
    stopTimer,
  ]);

  const handleStopAllAudio = useCallback(() => {
    stopAllAudio();
  }, [stopAllAudio]);

  const handleResumeAllAudio = useCallback(() => {
    resumeAllAudio();
  }, [resumeAllAudio]);

  const currentTrack = filteredTracks[trackIndex];

  const handleLogout = async () => {
    try {
      await signOut();
      console.log("Logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleAddTodo = useCallback(
    (todoText) => {
      const timerMatch = todoText.match(/for (\d+)\s?min/i);
      if (timerMatch) {
        const minutes = parseInt(timerMatch[1], 10);
        setTimerMinutes(minutes);
        setTimerSeconds(0);
        startTimer(() => setShowBreathingAnimation(true));
        togglePlayback();
      }

      setTodos((prevTodos) => [
        ...prevTodos,
        {
          text: todoText.replace(/for \d+\s?min/i, "").trim(),
          completed: false,
        },
      ]);
    },
    [setTimerMinutes, setTimerSeconds, startTimer, togglePlayback]
  );

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        event.code === "Space" &&
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "TEXTAREA"
      ) {
        event.preventDefault(); // Prevent scrolling
        handlePlayPauseStop();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handlePlayPauseStop]);

  useEffect(() => {
    if (showSidebar && todoInputRef.current) {
      todoInputRef.current.focus();
    }
  }, [showSidebar]);

  const toggleTodo = useCallback((index) => {
    setTodos((prevTodos) =>
      prevTodos.map((todo, i) =>
        i === index ? { ...todo, completed: !todo.completed } : todo
      )
    );
  }, []);

  const handleNoiseToggle = useCallback(
    (noiseName) => {
      setBackgroundNoises((noises) =>
        noises.map((noise, index) => {
          if (noise.name === noiseName) {
            const newNoise = { ...noise, isActive: !noise.isActive };
            setBackgroundNoiseVolume(
              index,
              newNoise.isActive ? newNoise.volume : 0
            );
            return newNoise;
          }
          return noise;
        })
      );
    },
    [setBackgroundNoiseVolume]
  );

  const handleVolumeChange = useCallback(
    (noiseName, newVolume) => {
      setBackgroundNoises((noises) =>
        noises.map((noise, index) => {
          if (noise.name === noiseName) {
            setBackgroundNoiseVolume(index, newVolume);
            return { ...noise, volume: newVolume };
          }
          return noise;
        })
      );
    },
    [setBackgroundNoiseVolume]
  );

  const handleGenreChange = useCallback(
    (newGenre) => {
      setGenre(newGenre);
      // Use setTimeout to ensure the filteredTracks array is updated
      setTimeout(() => {
        playTrack(0);
      }, 0);
    },
    [playTrack]
  );

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="h-screen flex flex-col text-white relative overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center z-0 transition-opacity duration-500"
        style={{ backgroundImage: `url(${currentTrack.albumArt})` }}
      />
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm z-10" />

      <div className="relative z-20 flex flex-col h-full">
        <HeaderSection
          genre={genre}
          onGenreChange={handleGenreChange}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          user={user}
          showProfileMenu={showProfileMenu}
          setShowProfileMenu={setShowProfileMenu}
          handleLogout={handleLogout}
        />

        <div className="flex-grow flex items-center justify-center">
          {isEditingTimer ? (
            <div className="w-full flex flex-col items-center justify-center space-y-6">
              <div className="flex items-center space-x-4">
                <div className="relative bg-slate-700/50 rounded-3xl border-2 border-white/20 p-2">
                  <input
                    type="number"
                    value={timerMinutes.toString().padStart(2, "0")}
                    onChange={(e) => {
                      const value = Math.min(
                        99,
                        Math.max(0, parseInt(e.target.value) || 0)
                      );
                      setTimerMinutes(value);
                    }}
                    className="w-[200px] text-[11rem] font-bold text-center bg-transparent text-white focus:outline-none"
                  />
                </div>
                <span className="text-[11rem] font-bold text-white">:</span>
                <input
                  type="number"
                  value={timerSeconds.toString().padStart(2, "0")}
                  onChange={(e) => {
                    const value = Math.min(
                      59,
                      Math.max(0, parseInt(e.target.value) || 0)
                    );
                    setTimerSeconds(value);
                  }}
                  className="w-[200px] text-[11rem] font-bold text-center bg-transparent text-white focus:outline-none"
                />
              </div>
              <Button
                onClick={() => {
                  setIsEditingTimer(false);
                  resetTimer();
                }}
                className="px-8 py-3 text-xl font-semibold bg-white text-slate-800 rounded-full hover:bg-opacity-90 transition-colors"
              >
                Set
              </Button>
            </div>
          ) : (
            <button
              onClick={() => setIsEditingTimer(true)}
              className="text-[11rem] font-bold hover:text-amber-500 transition-colors"
            >
              {`${timerMinutes.toString().padStart(2, "0")}:${timerSeconds
                .toString()
                .padStart(2, "0")}`}
            </button>
          )}
        </div>
        {/* Bottom Section */}
        <div className="w-full">
          {/* Playback Controls */}
          <div className="flex justify-center items-center space-x-8 mb-6">
            <Button variant="ghost" size="icon" onClick={skipToPrevious}>
              <SkipBack className="w-8 h-8" />
            </Button>
            <Button
              variant="outline"
              size="lg"
              className="w-16 h-16 rounded-full bg-transparent border border-white text-white hover:bg-white hover:text-black transition-colors text-2xl font-bold"
              onClick={handlePlayPauseStop}
            >
              {isPlaying ? (isStopped ? "⏹" : "⏸") : "▶"}
            </Button>
            <Button variant="ghost" size="icon" onClick={skipToNext}>
              <SkipForward className="w-8 h-8" />
            </Button>
          </div>

          <div className="flex-grow flex flex-col justify-center items-center ">
            <AnimatePresence mode="wait">
              {showDetails ? (
                <motion.div
                  key="details"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <TrackInfo track={currentTrack} isFavorite={isFavorite} />
                </motion.div>
              ) : (
                <motion.div
                  key="no-details"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="text-center"
                ></motion.div>
              )}
            </AnimatePresence>

            <Button
              variant="link"
              onClick={() => setShowDetails(!showDetails)}
              className=" text-gray-300 hover:text-white transition-colors"
            >
              {showDetails ? "Hide Details" : "Show Details"}
            </Button>
          </div>

          {/* Volume Control */}
          <div className="flex items-center justify-center space-x-2 mb-6">
            {volume === 0 ? (
              <VolumeX className="w-5 h-5" />
            ) : (
              <Volume2 className="w-5 h-5" />
            )}
            <Slider
              value={[volume * 100]}
              max={100}
              step={1}
              onValueChange={(value) => setVolume(value[0] / 100)}
              className="w-48"
            />
          </div>

          {/* Ambience Options */}
          <BackgroundNoiseSelector
            backgroundNoises={backgroundNoises}
            onNoiseToggle={handleNoiseToggle}
            onVolumeChange={handleVolumeChange}
          />
        </div>
      </div>

      {/* Sidebar */}
      <AnimatePresence>
        {showSidebar && (
          <motion.div
            className="absolute left-0 top-0 h-full w-2/6 bg-gray-800 bg-opacity-50 backdrop-blur-lg shadow-2xl p-4 overflow-y-auto z-20"
            initial={{ opacity: 0, x: -300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Todo List</h2>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowSidebar(false)}
                className="hover:bg-gray-700/50 transition-colors"
              >
                <X className="w-5 h-5" />
              </Button>
            </div>
            <TodoInput ref={todoInputRef} onAddTodo={handleAddTodo} />
            <TodoList todos={todos} toggleTodo={toggleTodo} />
          </motion.div>
        )}
      </AnimatePresence>

      <BreathingAnimation
        isVisible={showBreathingAnimation}
        onClose={() => setShowBreathingAnimation(false)}
      />

      <audio ref={audioRef} src={currentTrack.audioSrc} onEnded={skipToNext} />
    </div>
  );
};

export default MusicPlayer;
