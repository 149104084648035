import React, { useState, forwardRef } from "react";
import { PlusCircle, CheckCircle } from "lucide-react";

const TodoInput = forwardRef(({ onAddTodo }, ref) => {
  const [todoInput, setTodoInput] = useState("");

  const handleInputChange = (e) => {
    setTodoInput(e.target.value);
  };

  const handleAddTodo = () => {
    if (todoInput.trim()) {
      onAddTodo(todoInput.trim());
      setTodoInput("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddTodo();
    }
  };

  return (
    <div className="relative mb-4">
      <input
        ref={ref}
        type="text"
        value={todoInput}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Add a new todo (e.g., 'programming for 5 min')..."
        className="w-full py-3 px-4 pr-12 bg-white/10 backdrop-blur-md rounded-full text-white placeholder-white/50 outline-none focus:ring-2 focus:ring-amber-500 transition-all duration-300"
      />
      <button
        onClick={handleAddTodo}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-amber-500 hover:bg-amber-600 text-white p-2 rounded-full transition-colors duration-300 flex items-center justify-center"
      >
        <PlusCircle className="w-5 h-5" />
      </button>
    </div>
  );
});

const TodoList = ({ todos, toggleTodo }) => {
  return (
    <ul className="mb-4 space-y-2">
      {todos.map((todo, index) => (
        <li
          key={index}
          className={`flex items-center p-2 rounded-lg ${
            todo.completed ? "bg-gray-600/50" : "bg-gray-800/50"
          }`}
        >
          <button
            onClick={() => toggleTodo(index)}
            className="mr-2 flex-shrink-0"
          >
            <CheckCircle
              className={`w-5 h-5 ${
                todo.completed ? "text-green-500" : "text-gray-400"
              }`}
            />
          </button>
          <span
            className={`${
              todo.completed ? "line-through text-gray-400" : "text-white"
            }`}
          >
            {todo.text}
          </span>
        </li>
      ))}
    </ul>
  );
};

export { TodoInput, TodoList };
