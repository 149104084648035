import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import * as THREE from "three";

const ImmersiveBreathingGlobe = ({ isVisible, onClose }) => {
  const [stage, setStage] = useState("breathing");
  const globeRef = useRef(null);
  const sceneRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    if (isVisible && stage === "breathing") {
      const scene = new THREE.Scene();
      sceneRef.current = scene;
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
      });
      renderer.setSize(window.innerWidth, window.innerHeight);
      globeRef.current.appendChild(renderer.domElement);

      // Create a more modern grid background
      const gridSize = 200;
      const gridDivisions = 40;
      const gridHelper = new THREE.GridHelper(
        gridSize,
        gridDivisions,
        0x1a1a1a,
        0x0a0a0a
      );
      gridHelper.position.y = -10;
      gridHelper.rotation.x = Math.PI / 2;
      scene.add(gridHelper);

      // Add subtle fog for depth effect
      scene.fog = new THREE.Fog(0x000000, 10, 150);

      const geometry = new THREE.SphereGeometry(5, 128, 128);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          colorA: { value: new THREE.Color(0x2a2a2a) },
          colorB: { value: new THREE.Color(0x5a5a5a) },
        },
        vertexShader: `
          varying vec2 vUv;
          varying vec3 vNormal;
          void main() {
            vUv = uv;
            vNormal = normalize(normalMatrix * normal);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform float time;
          uniform vec3 colorA;
          uniform vec3 colorB;
          varying vec2 vUv;
          varying vec3 vNormal;
          
          void main() {
            vec3 color = mix(colorA, colorB, sin(time + vUv.y * 15.0) * 0.5 + 0.5);
            float fresnel = pow(1.0 - dot(vNormal, vec3(0.0, 0.0, 1.0)), 3.0);
            vec3 finalColor = mix(color, vec3(1.0), fresnel * 0.5);
            gl_FragColor = vec4(finalColor, 0.9);
          }
        `,
        transparent: true,
      });
      const globe = new THREE.Mesh(geometry, material);
      scene.add(globe);

      // Improve glow effect
      const glowGeometry = new THREE.SphereGeometry(5.2, 128, 128);
      const glowMaterial = new THREE.ShaderMaterial({
        uniforms: {
          coefficient: { value: 0.8 },
          power: { value: 2.5 },
          glowColor: { value: new THREE.Color(0x3a3a3a) },
        },
        vertexShader: `
          varying vec3 vNormal;
          void main() {
            vNormal = normalize(normalMatrix * normal);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform float coefficient;
          uniform float power;
          uniform vec3 glowColor;
          varying vec3 vNormal;
          void main() {
            float intensity = pow(coefficient - dot(vNormal, vec3(0, 0, 1.0)), power);
            gl_FragColor = vec4(glowColor, 1.0) * intensity;
          }
        `,
        side: THREE.BackSide,
        blending: THREE.AdditiveBlending,
        transparent: true,
      });
      const glowMesh = new THREE.Mesh(glowGeometry, glowMaterial);
      scene.add(glowMesh);

      // Add ambient light for softer overall lighting
      const ambientLight = new THREE.AmbientLight(0x404040, 0.5);
      scene.add(ambientLight);

      // Improve point light
      const light = new THREE.PointLight(0xffffff, 1, 100);
      light.position.set(10, 10, 10);
      scene.add(light);

      camera.position.z = 15;

      let scale = 1;
      let growing = true;

      const animate = (time) => {
        requestAnimationFrame(animate);
        material.uniforms.time.value = time / 1000;

        if (growing) {
          scale += 0.002;
          if (scale >= 1.15) growing = false;
        } else {
          scale -= 0.002;
          if (scale <= 1) growing = true;
        }

        globe.scale.set(scale, scale, scale);
        glowMesh.scale.set(scale, scale, scale);

        // Rotate grid for dynamic effect
        gridHelper.rotation.z += 0.0005;

        renderer.render(scene, camera);
      };
      animate();

      const breathingAnimation = async () => {
        await controls.start({
          opacity: [0.7, 1, 0.7],
          scale: [1, 1.15, 1],
          transition: { duration: 8, repeat: Infinity, ease: "easeInOut" },
        });
      };
      breathingAnimation();

      const timer = setTimeout(() => {
        setStage("explosion");
      }, 6000);

      return () => {
        clearTimeout(timer);
        renderer.dispose();
      };
    }
  }, [isVisible, stage, controls]);

  const handleAnimationComplete = () => {
    if (stage === "explosion") {
      setTimeout(() => setStage("focus"), 1000);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 bg-opacity-95">
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `
          radial-gradient(circle, rgba(32,32,32,0.4) 1px, transparent 1px),
          radial-gradient(circle, rgba(32,32,32,0.4) 1px, transparent 1px)
        `,
          backgroundSize: "30px 30px",
          backgroundPosition: "0 0, 15px 15px",
        }}
      ></div>
      <AnimatePresence>
        {stage === "breathing" && (
          <motion.div
            key="breathing"
            className="relative w-screen h-screen flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div ref={globeRef} className="w-full h-full" />
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              className="absolute text-gray-300 text-2xl md:text-4xl font-bold text-center"
              style={{
                textShadow: "0 0 10px rgba(255,255,255,0.5)",
              }}
            >
              Breathe Deeply
            </motion.div>
          </motion.div>
        )}
        {stage === "explosion" && (
          <motion.div
            key="explosion"
            className="relative w-screen h-screen flex items-center justify-center overflow-hidden"
            onAnimationComplete={handleAnimationComplete}
          >
            <motion.div
              initial={{ scale: 1, opacity: 1 }}
              animate={{ scale: 20, opacity: 0 }}
              transition={{ duration: 2, ease: "easeIn" }}
              className="w-64 h-64 rounded-full bg-gradient-to-r from-gray-700 via-gray-600 to-gray-500 blur-xl"
            />
            {[...Array(200)].map((_, i) => (
              <motion.div
                key={i}
                initial={{ x: 0, y: 0, opacity: 0, scale: 0 }}
                animate={{
                  x: (Math.random() - 0.5) * window.innerWidth,
                  y: (Math.random() - 0.5) * window.innerHeight,
                  opacity: [0, 1, 0],
                  scale: [0, 1, 0],
                }}
                transition={{ duration: 2, ease: "easeOut" }}
                className="absolute w-2 h-2 rounded-full"
                style={{
                  background: `hsl(${Math.random() * 60 + 180}, 20%, ${
                    Math.random() * 30 + 50
                  }%)`,
                  boxShadow: "0 0 20px 10px rgba(255, 255, 255, 0.2)",
                }}
              />
            ))}
          </motion.div>
        )}
        {stage === "focus" && (
          <motion.div
            key="focus"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-center"
          >
            <motion.div
              animate={{
                scale: [1, 1.05, 1],
                opacity: [0.8, 1, 0.8],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="text-7xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-gray-300 via-gray-100 to-gray-300"
              style={{
                textShadow: "0 0 20px rgba(255,255,255,0.3)",
              }}
            >
              Focus Activated
            </motion.div>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-2xl text-gray-300"
              style={{
                textShadow: "0 0 10px rgba(255,255,255,0.2)",
              }}
            >
              Your cosmic productivity journey begins
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-300 text-xl hover:text-gray-100 transition-colors"
        style={{
          textShadow: "0 0 10px rgba(255,255,255,0.3)",
        }}
      >
        ✕
      </button>
    </div>
  );
};

export default ImmersiveBreathingGlobe;
