import React from "react";
import { ThumbsUp, ThumbsDown, Share2 } from "lucide-react";
import { Button } from "./ui/button";

const TrackInfo = ({ track, isFavorite, onToggleFavorite, onDislike }) => (
  <div className="text-center animate-fadeIn">
    <h2 className="text-4xl font-bold mb-2">{track.name}</h2>
    <p className="text-xl text-gray-300 mb-4">{track.artist}</p>
    <div className="flex justify-center space-x-4 mb-6">
      <Button
        variant="ghost"
        size="icon"
        onClick={onToggleFavorite}
        className={`rounded-full ${
          isFavorite
            ? "bg-amber-500 text-white"
            : "text-white hover:bg-white/20"
        }`}
      >
        <ThumbsUp className="w-6 h-6" />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={onDislike}
        className="rounded-full text-white hover:bg-white/20"
      >
        <ThumbsDown className="w-6 h-6" />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        className="rounded-full text-white hover:bg-white/20"
      >
        <Share2 className="w-6 h-6" />
      </Button>
    </div>
  </div>
);

export default TrackInfo;
